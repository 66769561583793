import type { UserWorkspaceRole } from '~/types/models/user';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'user-workspace-roles';
const populate = {};
const searchFields = ['role'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(
    api,
    entityName,
    searchFields,
    populate,
  ) as GenericApi<UserWorkspaceRole>;

  return theApi;
};
