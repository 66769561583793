import type { FactureValidation } from '~/types/models/factureValidation';
import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'facture-validations';

const populate = {
  users: true,
  domaines: true,
};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory<FactureValidation>(api, entityName, searchFields, populate);

  return theApi;
};
