import type { Chantier } from '~/types/models/chantier';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'chantiers';

const populate = {
  conduc: true,
  projet: true,
};

const searchFields = [''];

type Api = GenericApi<Chantier> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
