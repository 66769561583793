import type { SignatureProvider } from '~/types/models/signatureProvider';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'signature-providers';

const populate = {};

const searchFields = [''];

type Api = GenericApi<SignatureProvider> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
