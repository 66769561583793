import { method } from 'lodash';
import type { TableFiltersValues } from '~/types/TableTypes';
import type { Facture } from '~/types/models/facture';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'factures';

const populate = {
  document: { populate: { media: true } },
  reglements: {
    sort: ['date:asc'],
  },
  domaine: true,
  affectation: true,
  categorie: true,
  emetteur: true,
  bdc: {
    populate: {
      documents: { populate: { media: true } },
      lignes: true,
      avenants: {
        populate: {
          lignes: true,
          documents: { populate: { media: true } },
        },
      },
    },
  },
  validation: {
    populate: {
      users: true,
    },
  },
  historiqueDatas: true,
  avoir_facture: {
    populate: {
      emetteur: true,
    },
  },
};

const searchFields = [
  'emetteur.raisonSociale',
  'libelle',
  'reference',
  'bdc.reference',
  'entiteRattachementLabel',
  'montantHT',
];

type Api = GenericApi<Facture> & {
  getFilters: () => Promise<TableFiltersValues>;
  facturesAlreadyImported: (filenames: string[]) => Promise<string[]>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api<Promise<TableFiltersValues>>(`${entityName}/filters`, {});
    } catch (error) {}
    return res || {};
  };

  theApi.facturesAlreadyImported = async (filenames: string[]) => {
    let res: string[] = [];
    try {
      const params = { filenames: filenames };

      res = await api<Promise<string[]>>(`${entityName}/file-already-imported?${useQs(params)}`, {
        method: 'GET',
      });
    } catch (error) {}
    return res;
  };

  return theApi;
};
