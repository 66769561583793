import type { Annonce, QuotasAnnonce } from '~/types/models/annonce';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'annonces';

const populate = {
  terrain: {
    populate: {
      secteur: true,
      documents: { populate: { media: true } },
      agence: true,
      commercial: true,
    },
  },
  version: { populate: { visuel: true } },
};

const searchFields = [
  'terrain.adresse_commune',
  'terrain.adresse_cp',
  'reference',
  'version.libelle',
];

type Api = GenericApi<Annonce> & {
  quotasParAgence: () => Promise<QuotasAnnonce[]>;
  getFilters: () => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.quotasParAgence = async () => {
    let res: any;
    try {
      res = await api<QuotasAnnonce>(`${entityName}/quotas`, {});
    } catch (error) {
      return [];
    }
    return res.data;
  };

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      res = null;
    }
    return res;
  };

  return theApi;
};
