import type { TypeDocument } from '~/types/models/typeDocument';
import type { ApiClient, ApiResult, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'types-de-document';

const populate = {};

const searchFields = ['libelle'];

type Api = GenericApi<TypeDocument> & {
  countDocsByTypes: () => Promise<ApiResult<{ [key: string]: number }>>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.countDocsByTypes = async () => {
    let res: ApiResult<{ [key: number]: number }>;
    try {
      const params = {};

      res = await api(`${entityName}/count-docs?${useQs(params)}`, {
        method: 'GET',
      });
    } catch (error) {
      // @ts-ignore
      res = { data: {} };
    }

    return res;
  };

  return theApi;
};
