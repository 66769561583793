import type { FactureDomaine } from '~/types/models/factureDomaine';
import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'facture-domaines';

const populate = {
  affectations: {
    sort: 'id:asc',
    populate: {
      categories: {
        sort: 'id:asc',
      },
    },
  },
};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory<FactureDomaine>(api, entityName, searchFields, populate);

  return theApi;
};
