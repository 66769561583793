export default () => {
  return {
    search: async (q: string) => {
      try {
        const data = await $fetch(`https://api-adresse.data.gouv.fr/search/?q=${q}`);
        const value = data as any;
        return value?.features || [];
      } catch (e) {
        console.error(e);
      }

      return [];
    },
  };
};
