import type { FactureCategorie } from '~/types/models/factureCategorie';
import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'facture-categories';

const populate = {};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory<FactureCategorie>(api, entityName, searchFields, populate);

  return theApi;
};
