import type { Gamme } from '~/types/models/gamme';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'gammes';
const populate = {
  types_articles: true,
};
const searchFields = ['libelle', 'description'];

type Api = GenericApi<Gamme> & {
  findByTypeArticle: (typeArticleId: number) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findByTypeArticle = async (typeArticleId: number) =>
    await theApi.find(1, '', '', 100, {
      $or: [
        { types_articles: { $or: [{ parent: { id: typeArticleId } }, { id: typeArticleId }] } },
      ],
    });

  return theApi;
};
