import type { Garantie } from '~/types/models/garantie';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'garanties';

const populate = {
  documents: { populate: { media: true } },
  projet: true,
  garant_do: true,
  garant_gl: true,
};

const searchFields = [''];

type Api = GenericApi<Garantie> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
