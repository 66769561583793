export const useStrapiQueryParams = (
  ctx: {
    page?: number;
    sortField?: string | string[];
    query?: string;
    limit?: number;
    filters?: object;
    populate?: object;
    searchFields?: string[];
  } = {
    page: 1,
    sortField: ['createdAt:desc'],
    query: '',
    limit: 100,
    filters: {},
    populate: {},
    searchFields: [],
  },
) => {
  const params: QueryParam = {
    populate: ctx.populate || {},
    filters: {},
    sort: [] as string[],
    pagination: {
      page: ctx.page,
      pageSize: ctx.limit,
    },
  };
  params.filters = {
    ...(ctx.filters || {}),
  };

  if (ctx.limit === -1) {
    if (params.pagination.page) {
      delete params.pagination.page;
    }
    if (params.pagination.pageSize) {
      delete params.pagination.pageSize;
    }
    params.pagination.limit = -1;
  }

  if (ctx.sortField) {
    if (Array.isArray(ctx.sortField)) {
      params.sort = ctx.sortField;
    } else {
      params.sort = [ctx.sortField];
    }
  }
  if (ctx.query) {
    params.filters = {
      $and: [
        { ...(params.filters || {}) },
        { $or: useSearchCriterias(ctx.query, ctx.searchFields || []) },
      ],
    };
  }

  return params;
};
