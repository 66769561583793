import type { ApiClient, ApiResult, GenericApi } from '~~/composables/useApiFactory';
import { populate as metrePopulate } from './metres';
import type { VersionConstruction } from '~/types/models/versionConstruction';

const entityName = 'construction-versions';
const populate = {
  visuel: true,
  medias: true,
  modele: { populate: { gamme: true } },
  documents: { populate: { media: true } },
  metre_modele: { populate: { metre: { populate: metrePopulate } } },
};
const searchFields = ['libelle', 'searchString'];

type Api = GenericApi<VersionConstruction> & {
  isUnique: (slug: string, modeleId: number) => Promise<boolean>;
  fetchAll: () => Promise<ApiResults<VersionConstruction> | null>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.isUnique = async (slug: string, modeleId: number) => {
    const result = await api<boolean>(`${entityName}/unique/${modeleId}/${slug}`, {});
    return result;
  };

  theApi.fetchAll = async () => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {
        filters: {},
        sort: ['modele.libelle:asc', 'libelle'],
        pagination: { limit: -1 },
      };
      $loading.value = true;
      res = await api<ApiResults<VersionConstruction>>(`${entityName}?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  };

  return theApi;
};
