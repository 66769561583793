import type{ ApiClient } from '~~/composables/useApiFactory';

const entityName = 'parametres';

const populate = {};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate);

  return theApi;
};
