import type { Lot } from '~/types/models/lot';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'lots';

const populate = {
  parent: { populate: { parent: true } },
  sous_lots: true,
  notice_section: { populate: { parent: true } },
};

const searchFields = ['code', 'libelle'];

type Api = GenericApi<Lot> & {
  findSousLots: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  findLots: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  searchSousLots: (query: string) => Promise<any>;
  findSousLotsByLot: (lotId: number) => Promise<any>;
  isCodeUnique: (code: string) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findSousLots = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, {
      parent: { id: { $notNull: true } },
    });

  theApi.findLots = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, { parent: { id: { $null: true } } });

  theApi.searchSousLots = async (query: string) =>
    await theApi.find(1, '', query, 5, {
      parent: { id: { $notNull: true } },
    });

  theApi.findSousLotsByLot = async (lotId: number) =>
    await theApi.find(1, 'code:asc', '', 100, {
      parent: { id: lotId },
    });

  theApi.isCodeUnique = async (code: string) => {
    const result = await api(`${entityName}/unique/${code}`, {});
    return result;
  };

  return theApi;
};
