import type { User } from '~/types/models/user';
import type { ApiClient, Creatable, QueryParam, Updatable } from '~~/composables/useApiFactory';

const theEntityName = 'users';

const thePopulate = {
  user_workspace_roles: {
    sort: 'role:asc',
    populate: {
      workspace: true,
      agence: true,
      representant: true,
    },
  },
  objectifsVentes: true,
};

const theSearchFields = [''];

export default (providedApi: ApiClient) => {
  const theApi = {
    find: async (
      page = 1,
      sortField = '',
      query = '',
      limit = 100,
      filters: object = {},
      populate: object = thePopulate,
      api: ApiClient = providedApi,
      entityName: string = theEntityName,
      searchFields: string[] = theSearchFields,
    ) => {
      const { $loading } = useNuxtApp();
      let res: User[];
      try {
        const params: QueryParam = {
          populate: populate,
          filters: {},
          sort: [] as string[],
          pagination: {
            page: page,
            pageSize: limit,
          },
        };
        params.filters = {
          ...(filters || {}),
        };

        if (limit === -1) {
          delete params.pagination.pageSize;
          params.pagination.limit = -1;
        }

        if (sortField) {
          if (Array.isArray(sortField)) {
            params.sort = sortField;
          } else {
            params.sort = [sortField];
          }
        }
        if (query)
          params.filters = {
            ...(params.filters || {}),
            $or: useSearchCriterias(query, searchFields),
          };
        $loading.value = true;
        res = await api(`${entityName}?${useQs(params)}`, {});
      } catch (error) {
        console.error(error);
        res = [];
      }
      $loading.value = false;
      return res as User[];
    },

    findByRoles: async (rolesName: string[]) => {
      return await theApi.find(1, '', '', 100, {
        user_workspace_roles: {
          role: {
            $in: rolesName,
          },
          workspace: useAuthStore().workspace.id,
        },
      });
    },

    update: async (entity: Updatable<User>) => {
      if (!entity.id) return null;
      const { $loading } = useNuxtApp();
      let res: User | null;
      const params = { populate: thePopulate };
      try {
        $loading.value = true;
        entity.username = entity.email;
        res = await providedApi(`${theEntityName}/${entity.id}?${useQs(params)}`, {
          method: 'PUT',
          body: { ...entity },
        });
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res || null;
    },

    create: async (entity: Creatable<User>) => {
      const { $loading } = useNuxtApp();
      let res: User | null;
      const params = { populate: thePopulate };
      try {
        $loading.value = true;
        entity.username = entity.email;
        res = await providedApi(`${theEntityName}?${useQs(params)}`, {
          method: 'POST',
          body: { ...entity },
        });
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res || null;
    },

    alreadyExistsInWorkspace: async (email: string) => {
      const { $loading } = useNuxtApp();
      let res: boolean;
      try {
        $loading.value = true;
        res = await providedApi(`workspaces/user/exists/${email}`, {});
      } catch (error) {
        console.error(error);
        res = false;
      }
      $loading.value = false;
      return res;
    },

    alreadyExists: async (email: string) => {
      const { $loading } = useNuxtApp();
      let res: User | false;
      try {
        $loading.value = true;
        res = await providedApi(`${theEntityName}/exists/${email}`, {});
      } catch (error) {
        console.error(error);
        res = false;
      }
      $loading.value = false;
      return res;
    },

    register: async (entity: Creatable<User>) => {
      const { $loading } = useNuxtApp();
      let res: { jwt: string; user: User } | null;
      const params = { populate: thePopulate };
      try {
        $loading.value = true;
        entity.username = entity.email;
        res = await providedApi(`/auth/local/register`, {
          method: 'POST',
          body: { ...entity },
        });
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res?.user || null;
    },
  };
  return theApi;
};
