export default (api) => ({
  login: async (username, password) => {
    return await api.login({ identifier: username, password });
  },
  logout: () => {
    api.logout();
  },

  sendForgotPasswordEmail: async (email) => {
    return await api.forgotPassword({ email: encodeURI(email) });
  },
  resetPassword: async (password, code) => {
    try {
      return await api.resetPassword({
        code,
        password,
        passwordConfirmation: password,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  },
});
