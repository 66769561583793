import type { Workspace } from '~/types/models/workspace';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'workspaces';

const populate = {
  echeanciers: true,
};

const searchFields = [''];

type Api = GenericApi<Workspace> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
