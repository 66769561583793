import type { LigneMetre } from '~/types/models/ligneMetre';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'metre-lignes';

export const populate = {
  article: {
    populate: { lot: true, sous_lot: true, gamme: true, type: { populate: { parent: true } } },
  },
  metre: true,
};

const searchFields = [
  'article.code',
  'article.libelle',
  'article.lot.code',
  'article.sous_lot.code',
];

type Api = GenericApi<LigneMetre> & {
  findByMetreId: (metreId: number, sortField: string, query: string) => Promise<any>;
  findOnlyWithNotNullQuantite: (metreId: number) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findByMetreId = async (metreId: number, sortField: string, query: string) => {
    if (!metreId) return { error: 'metreId is required' };
    const res = await theApi.find(1, sortField, query, 9999, { metre: { id: metreId } });
    return res;
  };

  theApi.findOnlyWithNotNullQuantite = async (metreId: number) =>
    await theApi.find(1, '', '', 9999, {
      metre: metreId,
      $and: [{ quantite: { $notNull: true } }, { quantite: { $gt: 0 } }],
    });

  return theApi;
};
