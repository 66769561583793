import type { Avenant } from '~/types/models/avenant';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'contrat-avenants';

const populate = {
  options: {
    populate: {
      lignes: {
        populate: {
          article: {
            populate: {
              lot: true,
              sous_lot: true,
              gamme: true,
              type: true,
            },
          },
        },
      },
    },
  },
  articlesLibres: true,
  contrat: true,
  document: { populate: { media: true } },
  proposition: { populate: { media: true } },
};

const searchFields = [''];

type Api = GenericApi<Avenant> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
