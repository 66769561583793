export default () => {
  return {
    search: async (q: string) => {
      const res = await useFetch(
        `https://geo.api.gouv.fr/communes?nom=${q}&fields=nom,codesPostaux,code,lat,lon&format=geojson&geometry=centre&limit=5&boost=population`,
      );

      if (res && res.data) {
        const value = res.data.value as any;
        return value?.features || [];
      }
      return [];
    },
  };
};
