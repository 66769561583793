import type { CommandeLigne } from '~/types/models/commandeLigne';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'commande-lignes';

const populate = {
  commande: true,
};

const searchFields = [''];

type Api = GenericApi<CommandeLigne> & {
  getFilters: () => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
