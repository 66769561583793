import type { ApiClient } from '~~/composables/useApiFactory';
import { populate as MetrePopulate } from './metres';
import type { ModeleMetre } from '~/types/models/modeleMetre';

const entityName = 'metre-modeles';

const populate = {
  metre: { populate: MetrePopulate },
  construction_version: { populate: { modele: true } },
};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory<ModeleMetre>(api, entityName, searchFields, populate);

  return theApi;
};
