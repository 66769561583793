import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';
import { populate as lignePopulate } from './metreLignes';
import type { Metre } from '~/types/models/metre';

const entityName = 'metres';

export const populate = {
  avp: { populate: { projet: { populate: { avancement: { populate: { statut: true } } } } } },
  metreur: true,
  lignes: { populate: lignePopulate },
  options: {
    populate: {
      lignes: { populate: lignePopulate },
    },
  },
};

const searchFields = ['avp.projet.code'];

type Api = GenericApi<Metre> & {
  mergeOptions: (id: number, optionsIds: number[], isMajor: boolean) => Promise<any>;
  getMetas: (id: number) => Promise<any>;
  validateMetre: (id: number) => Promise<any>;
  fixMetre: (id: number) => Promise<any>;
  freeMetre: (id: number) => Promise<any>;
  articleQuantite: (id: number, codeArticle: string) => Promise<any>;
  updateMarge: (id: number, marge: number) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.mergeOptions = async (id: number, optionsIds: number[], isMajor: boolean) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/mergeOptions?${useQs(params)}`, {
        method: 'POST',
        body: { optionsIds, major: isMajor },
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.getMetas = async (id: number) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/metas?${useQs(params)}`, {
        method: 'GET',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.validateMetre = async (id: number) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/validate?${useQs(params)}`, {
        method: 'POST',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.fixMetre = async (id: number) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/fix?${useQs(params)}`, {
        method: 'POST',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.freeMetre = async (id: number) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/free?${useQs(params)}`, {
        method: 'POST',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.articleQuantite = async (id: number, codeArticle: string) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/q/${codeArticle}?${useQs(params)}`, {
        method: 'GET',
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.updateMarge = async (id: number, marge: number) => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {};
      $loading.value = true;
      res = await api(`${entityName}/${id}/marge?${useQs(params)}`, {
        method: 'PUT',
        body: { marge },
      });
    } catch (error) {
      res = null;
    }
    $loading.value = false;
    return res;
  };

  return theApi;
};
