import type { Secteur } from '~/types/models/secteur';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'secteurs';

const populate = {
  agences: true,
};

const searchFields = [''];

type Api = GenericApi<Secteur> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
