import type { Echeancier } from '~/types/models/echeancier';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'echeanciers';
const populate = { garants: true };
const searchFields = ['libelle'];

type Api = GenericApi<Echeancier>;

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
