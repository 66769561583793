import type { LignePrecommande } from '~/types/models/lignePrecommande';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'precommande-lignes';

export const populate = {
  precommande: {
    populate: {
      lot: {
        populate: {
          parent: true,
          sous_lots: true,
        },
      },
    },
  },
  article: {
    populate: {
      gamme: true,
      sous_lot: true,
    },
  },
  metre_ligne: {
    populate: {
      article: {
        populate: {
          gamme: true,
          sous_lot: true,
        },
      },
    },
  },
  devis: {
    populate: {
      media: true,
    },
  },
};

const searchFields = [''];

type Api = GenericApi<LignePrecommande> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
