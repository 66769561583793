import { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'avps';

const populate = { documents: { populate: { media: true } } };

const searchFields = ['version'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate);

  return theApi;
};
