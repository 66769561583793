import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';
import { populate as precommandePopulate } from './precommandes';
import { PrecommandeTypes } from '~/types/Enums';
import type { MetreTravaux } from '~/types/models/metreTravaux';

const entityName = 'metres-travaux';

const populate = {
  projet: {
    populate: {
      avps: {
        populate: {
          metre: true,
        },
        sort: { createdAt: 'desc' },
      },
      contrat: {
        populate: {
          avenants: {
            populate: {
              options: { populate: { lignes: { populate: { article: true } } } },
              articlesLibres: true,
              contrat: true,
            },
          },
        },
      },
    },
  },
  precommandes: { populate: precommandePopulate },
};

const searchFields = ['projet.code'];

type Api = GenericApi<MetreTravaux> & {
  validate: (id: number, type?: PrecommandeTypes) => Promise<boolean>;
  getFilters: () => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      res = null;
    }
    return res;
  };

  theApi.validate = async (id: number, type?: PrecommandeTypes) => {
    const { $loading } = useNuxtApp();
    try {
      $loading.value = true;
      await api(`${entityName}/validate/${id}`, {
        method: 'PUT',
        body: {
          data: {
            type: type || '',
          },
        },
      });
    } catch (error) {
      return false;
    }
    $loading.value = false;
    return true;
  };

  return theApi;
};
