import type { FactureAffectation } from '~/types/models/factureAffectation';
import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'facture-affectations';

const populate = {
  categories: { sort: 'libelle' },
};

const searchFields = ['libelle'];

export default (api: ApiClient) => {
  const theApi = useApiFactory<FactureAffectation>(api, entityName, searchFields, populate);

  return theApi;
};
