// DIFSSEUR
// <nom>Leboncoin.fr (Immo)</nom>
//         <diffusion_active_par_defaut>false</diffusion_active_par_defaut>
//         <nb_annonces_max selection_manuelle="false">50</nb_annonces_max>
//         <url_portail>http://www.leboncoin.fr</url_portail>
//         <url_logo_portail>https://common.ubiflow.net/images/logos/diffuseur/2798.jpg?ts=1650615461</url_logo_portail>

export type Diffuseur = {
  code: string;
  nom: string;
  diffusion_active_par_defaut: boolean;
  nb_annonces_max: number;
  nb_annonces_max_manuel: number;
  selection_manuelle: boolean;
  url_portail: string;
  url_logo_portail: string;
};

export type ParamsUbiflow = {
  active: boolean;
  identifiant: string;
  ftp: {
    login: string;
    password: string;
  };
  quotas?: {
    agences: { [key: number]: number };
  };
  quotasParAgence?: { agence_id: number; diffuseur_code: string; nb_annonces_max: number }[];
  diffuseurs: Diffuseur[];
};

export type ParamsAnnonces = {
  contenus: {
    titre: string;
    titreTM: string;
    accrocheModele: string;
    mentions: {
      'Annonces T': string;
      'Annonces T+M': string;
    };
    contact: {
      nom: string;
      telephone: string;
      email: string;
    };
  };
};

export type ParamsDiffusion = {
  ubiflow: ParamsUbiflow;
  annonces: ParamsAnnonces;
};

// create new empty object of type ParamsDiffusion
export const newParamsDiffusion = (): ParamsDiffusion => {
  return {
    ubiflow: {
      active: false,
      identifiant: '',
      ftp: {
        login: '',
        password: '',
      },
      quotas: {
        agences: {},
      },
      diffuseurs: [],
    },
    annonces: {
      contenus: {
        accrocheModele: '',
        titre: '',
        titreTM: '',
        mentions: {
          'Annonces T': '',
          'Annonces T+M': '',
        },
        contact: {
          nom: '',
          telephone: '',
          email: '',
        },
      },
    },
  };
};
