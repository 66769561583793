import { type ApiClient } from '~~/composables/useApiFactory';

const entityName = 'projet-statuts';

const populate = {
  avancements: { sort: 'ordre' },
};

const searchFields = ['code'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate);

  return theApi;
};
