import type { SectionNotice } from '~/types/models/sectionNotice';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'notice-sections';

const populate = {
  parent: { populate: { parent: true } },
  sous_lots: true,
};

const searchFields = ['code', 'libelle'];

type Api = GenericApi<SectionNotice> & {
  findSections: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  findSousSections: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  isCodeUnique: (code: string) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findSections = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, { parent: { id: { $null: true } } });

  theApi.findSousSections = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, { parent: { id: { $notNull: true } } });

  theApi.isCodeUnique = async (code: string) => {
    const result = await api(`${entityName}/unique/${code}`, {});
    return result;
  };

  return theApi;
};
