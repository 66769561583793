import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'gpt';

const populate = {
  role: true,
};

const searchFields = [''];

type Api = {
  prompt: (prompt: string) => Promise<{ role: string; content: string } | null>;
};

export default (api: ApiClient) => {
  const theApi = {
    prompt: async (prompt: string) => {
      const { $loading } = useNuxtApp();
      let res: { role: string; content: string } | null = null;
      try {
        $loading.value = true;
        res = await api(`${entityName}`, {
          method: 'POST',
          body: {
            prompt,
          },
        });
      } catch (error) {
        console.error(error);
        res = null;
      }
      $loading.value = false;
      return res;
    },
  };

  return theApi;
};
