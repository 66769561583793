import type { Client } from '~/types/models/client';
import type { FiltersValues } from '~/types/TableTypes';
import type { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'clients';
const populate = {
  projets: {
    populate: { avancement: { populate: { statut: true } } },
    filters: {
      supprime: { $ne: 1 },
    },
  },
  agence: true,
};
const searchFields = ['nom', 'prenom', 'email', 'raisonSociale'];

type Api = GenericApi<Client> & {
  getFilters: () => Promise<FiltersValues>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      res = null;
    }
    return res as FiltersValues;
  };

  return theApi;
};
