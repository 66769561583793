import type { Agence } from '~/types/models/agence';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'agences';
const populate = {};
const searchFields = ['name'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as GenericApi<Agence>;

  return theApi;
};
