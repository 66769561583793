import type { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'upload';

type Api = {
  upload: (
    file: File | File[] | null,
    entityId?: number,
    entityField?: string,
    model?: string,
    type?: string,
  ) => Promise<any>;
  delete: (id: number) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi: Api = {
    upload: async (
      file: File | File[] | null,
      entityId?: number,
      entityField?: string,
      model?: string,
      type?: string,
    ) => {
      if (!file) return Promise.resolve(null);
      const { $loading } = useNuxtApp();
      let res;
      try {
        const formData = new FormData();

        if (Array.isArray(file)) {
          for (let i = 0; i < file.length; i++) {
            const f = file[i];
            const fileInfo = {
              alternativeText: f.name,
              doc_type: type || '',
            };

            formData.append('files', f, crypto.randomUUID());
            formData.append('fileInfo', JSON.stringify(fileInfo));
          }
        } else {
          const fileInfo = {
            alternativeText: file.name,
          };
          formData.append('files', file, crypto.randomUUID());
          formData.append('fileInfo', JSON.stringify(fileInfo));
        }

        if (entityId && entityField && model) {
          formData.append('ref', model);
          formData.append('refId', entityId.toString());
          formData.append('field', entityField);
        }
        $loading.value = true;
        res = await api(`${entityName}`, {
          method: 'POST',
          body: formData,
        });
      } catch (error) {
        res = null;
      }
      $loading.value = false;
      return res;
    },

    delete: async (id: number) => {
      const { $loading } = useNuxtApp();
      let res;
      try {
        const params = {};
        $loading.value = true;
        res = await api(`${entityName}/files/${id}`, {
          method: 'DELETE',
        });
      } catch (error) {
        res = null;
      }
      $loading.value = false;
      return res;
    },
  };

  return theApi;
};
