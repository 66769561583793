import permissions from '~~/permissions';

export const usePermissions = (roles) => {
  const mergedPermissions = [];
  for (const role of roles) {
    const p = permissions[role];
    if (p) {
      if (!mergedPermissions.find((mp) => p.subject === mp.subject && p.action === mp.action)) {
        mergedPermissions.push(...p);
      }
    }
  }

  return mergedPermissions;
};
