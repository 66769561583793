import { CommandeStatus } from '~/types/Enums';
import type { Commande, CommandeAlert } from '~/types/models/commande';
import type { FiltersValues } from '~/types/TableTypes';
import type { ApiClient, ApiResult, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'commandes';

export const cmdPopulate = {
  documents: {
    populate: {
      media: true,
      signature: true,
    },
  },
  ssttfour: {
    populate: {
      documents: true,
    },
  },
  projet: { populate: { clients: true } },
  lignes: {
    populate: {
      sousLot: {
        populate: {
          parent: true,
        },
      },
    },
  },
  parent: true,
  domaine: true,
  affectation: true,
  categorie: true,
};

const populate = {
  ...cmdPopulate,
  avenants: { populate: { ...cmdPopulate } },
};

const searchFields = [
  'projet.code',
  'type',
  'ssttfour.raisonSociale',
  'libelle',
  'reference',
  'entiteRattachementLabel',
];

type Api = GenericApi<Commande> & {
  getFilters: () => Promise<FiltersValues>;
  findCommandesEligiblesForMerge: (commande: Commande) => Promise<Commande[]>;
  findByProjet: (projetId: number, populate?: object) => Promise<Commande[]>;
  findAlerts: () => Promise<ApiResult<CommandeAlert[]>>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.getFilters = async () => {
    let res;
    try {
      res = await api(`${entityName}/filters`, {});
    } catch (error) {
      console.error(error);
      res = {};
    }
    return res as FiltersValues;
  };

  theApi.findCommandesEligiblesForMerge = async (commande: Commande) => {
    if (!commande.projet || !commande.ssttfour) return [];
    const lotId = commande.lignes[0].sousLot.parent.id;
    const filters = {
      id: { $ne: commande.id },
      type: { $eq: commande.type },
      statut: { $eq: CommandeStatus.ATTRIBUE },
      projet: { id: { $eq: commande.projet.id } },
      ssttfour: { id: { $eq: commande.ssttfour.id } },
      lignes: { sousLot: { parent: { id: { $eq: lotId } } } },
    };

    const res = await theApi.find(1, '', '', 100, filters);
    if (res?.data) {
      return res.data;
    }

    return [];
  };

  theApi.findByProjet = async (projetId: number, populate?: object) => {
    const res = await theApi.find(
      1,
      'createdAt:desc',
      '',
      100,
      {
        projet: { id: { $eq: projetId } },
      },
      populate,
    );

    return res && res.data ? res.data : [];
  };

  theApi.findAlerts = async () => {
    const { $loading } = useNuxtApp();
    let res: ApiResult<CommandeAlert[]>;
    try {
      const params = { populate: populate };
      $loading.value = true;
      res = await api(`${entityName}/alert?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = { data: [] };
    }
    $loading.value = false;
    return res;
  };

  return theApi;
};
