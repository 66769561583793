import type { EPE } from '~/types/models/epe';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'epes';

const populate = {
  documents: { populate: { media: true } },
  projet: true,
};

const searchFields = [''];

type Api = GenericApi<EPE> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
