import type { Precommande } from '~/types/models/precommande';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';
import { populate as lignePopulate } from '~/api/lignesPrecommande';
import type { LignePrecommande } from '~/types/models/lignePrecommande';

const entityName = 'precommandes';

export const populate = {
  metre_travaux: {
    fields: ['id'],
  },
  lot: {
    populate: {
      parent: true,
      sous_lots: true,
    },
  },
  lignes: {
    populate: lignePopulate,
  },
};

const searchFields = [''];

type Api = GenericApi<Precommande> & {
  findPrecommandesEligiblesForMerge: (precmd: Precommande) => Promise<Precommande[]>;
  duplicate: (precmd: Precommande) => Promise<Precommande>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findPrecommandesEligiblesForMerge = async (precmd: Precommande) => {
    const lotId = precmd.lot?.parent?.id || precmd.lot?.id;
    const filters = {
      id: { $ne: precmd.id },
      type: { $eq: precmd.type },
      metre_travaux: { id: { $eq: precmd.metre_travaux.id } },
      lot: { $or: [{ parent: { id: { $eq: lotId } } }, { id: { $eq: lotId } }] },
    };

    const res = await theApi.find(1, '', '', 100, filters);
    if (res?.data) {
      const precommandeWithoutDevis = res.data.filter(
        (p: Precommande) => !p.lignes.some((l: LignePrecommande) => l.devis),
      );
      return precommandeWithoutDevis;
    }

    return [];
  };

  theApi.duplicate = async (precmd: Precommande) => {
    const { $loading } = useNuxtApp();
    let res;
    const params = { populate };
    try {
      $loading.value = true;
      res = await api(`${entityName}/duplicate/${precmd.id}?${useQs(params)}`, {
        method: 'GET',
      });
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res as Precommande;
  };

  return theApi;
};
