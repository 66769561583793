import type { OptionMetre } from '~/types/models/optionMetre';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';
import { OptionMetreStatus } from '~~/types/Enums';

const entityName = 'metre-options';

const populate = {
  metre: true,
  avenant: true,
  lignes: {
    populate: {
      article: { populate: { lot: true, sous_lot: true, gamme: true, type: true } },
      metre: true,
      option: { populate: { avenant: true } },
    },
  },
};

const searchFields = ['code', 'description'];

type Api = GenericApi<OptionMetre> & {
  findByMetreId: (metreId: number, sortField: string, query: string, filters?: any) => Promise<any>;
  findOptionsIntegreesByProjetId: (projetId: number) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findByMetreId = async (metreId: number, sortField: string, query: string, filters?: any) =>
    await theApi.find(1, sortField, query, 100, {
      metre: metreId,
      statut: OptionMetreStatus.CREE,
      ...filters,
    });

  theApi.findOptionsIntegreesByProjetId = async (projetId: number) =>
    await theApi.find(1, '', '', 100, {
      metre: { avp: { projet: projetId } },
      statut: OptionMetreStatus.INTEGRE,
    });

  return theApi;
};
