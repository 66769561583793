import { DateTime } from 'luxon';
import type { JalonPnl } from '~/types/Enums';
import type { PnlProjet } from '~/types/models/pnlProjet';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';
import type { Contrat } from '~~/types/models/contrat';

const entityName = 'contrats';

const populate = {
  documents: { populate: { media: true } },
  projet: {
    populate: {
      avps: { populate: { metre: true }, sort: ['createdAt:desc'] },
      avancement: true,
    },
  },
  avenants: {
    populate: {
      options: { populate: { lignes: { populate: { article: { type: true } } } } },
      articlesLibres: true,
      contrat: true,
      document: { populate: { media: true } },
      proposition: { populate: { media: true } },
    },
    sort: ['numero:desc'],
  },
};

const searchFields = [''];

type Api = GenericApi<Contrat> & {
  findByProjet: (id: number) => Promise<Contrat | null>;
  savePnl: (id: number, event: JalonPnl, date: string, entityId?: number) => Promise<PnlProjet>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.findByProjet = async (id: number) => {
    const res = await theApi.find(1, '', '', 1, { projet: id });
    let contrat: Contrat | null = null;
    if (res && res.data) {
      contrat = res.data[0];
    }

    // sort contrat.avenant by numéro and place null or 0 numero value at the and sorted by createdAt
    if (contrat && contrat.avenants) {
      contrat.avenants = contrat.avenants.sort((a, b) => {
        if (!a.numero && !b.numero) {
          return DateTime.fromISO(a.createdAt!) < DateTime.fromISO(b.createdAt!) ? -1 : 1;
        }
        if (!a.numero) {
          return 1;
        }
        if (!b.numero) {
          return -1;
        }
        return a.numero - b.numero;
      });
    }

    return contrat;
  };

  theApi.savePnl = async (id: number, event: JalonPnl, date: string, entityId?: number) => {
    const res: PnlProjet = await api(`${entityName}/${id}/savePnl`, {
      method: 'POST',
      body: { event, date, entityId: entityId || null },
    });
    return res;
  };

  return theApi;
};
