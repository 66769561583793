import type { TableFiltersValues } from '~/types/TableTypes';
import type { FactureReglement } from '~/types/models/factureReglement';
import { type ApiClient, type ApiResult } from '~~/composables/useApiFactory';

const entityName = 'facture-reglements';

const populate = {
  facture: {
    populate: {
      emetteur: true,
      domaine: true,
      document: { populate: { media: true } },
    },
  },
};

const searchFields = [
  'facture.libelle',
  'facture.entiteRattachementLabel',
  'facture.emetteur.raisonSociale',
  'facture.reference',
  'montant',
];

type Api = GenericApi<FactureReglement> & {
  getFilters: () => Promise<TableFiltersValues>;
  montantTotal: (ctx: {
    page?: number;
    sortField?: string | string[];
    query?: string;
    limit?: number;
    filters?: object;
    customPopulate?: object;
  }) => Promise<number>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;
  theApi.getFilters = async () => {
    let res;
    try {
      res = await api<Promise<TableFiltersValues>>(`${entityName}/filters`, {});
    } catch (error) {}
    return res || {};
  };

  theApi.montantTotal = async (ctx: {
    page?: number;
    sortField?: string | string[];
    query?: string;
    limit?: number;
    filters?: object;
    customPopulate?: object;
  }) => {
    let res;
    const params = useStrapiQueryParams({ ...ctx, searchFields });
    try {
      res = await api<Promise<ApiResult<{ montant: number }>>>(
        `${entityName}/montant-total?${useQs(params)}`,
        {},
      );
    } catch (error) {}
    return res?.data.montant || 0;
  };

  return theApi;
};
