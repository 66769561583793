import { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'projet-avancements';
const populate = {
  statut: true,
};
const searchFields = ['code'];

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate);

  return theApi;
};
