import type { Signature } from '~/types/models/signature';
import type { SignatureRequest } from '~/types/SignatureRequest';
import type { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'signature-request';

const populate = {};

const searchFields = [''];

type Api = {};

const getDocumentBlob = async (url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default (api: ApiClient) => {
  const theApi = {
    createSignatureRequest: async (
      signatureRequest: SignatureRequest,
    ): Promise<Signature | null> => {
      try {
        // transform document url into File and send it in FormData
        const formData = new FormData();

        formData.append('title', signatureRequest.title);

        // add document to formData
        const document = signatureRequest.document;
        // get document blob
        const docBlob = await getDocumentBlob(document.media.url);

        const documentInfos = [];

        if (docBlob) {
          formData.append('document', docBlob, document.name);
          documentInfos.push({
            sign: true,
            id: document.id,
          });
        }

        for (const complementaryDocument of signatureRequest.documentsComplementaires) {
          const docBlob = await getDocumentBlob(complementaryDocument.media.url);
          if (docBlob) {
            formData.append('documentsComplementaires', docBlob, complementaryDocument.name);
            documentInfos.push({
              sign: complementaryDocument.needSign,
              id: complementaryDocument.id,
            });
          }
        }

        formData.append('documentsInfos', JSON.stringify(documentInfos));

        // add destinataires to formData
        formData.append('destinataires', JSON.stringify(signatureRequest.destinataires));
        // add contreSignataires to formData
        formData.append('contreSignataires', JSON.stringify(signatureRequest.contreSignataires));
        // add fields to formData
        formData.append('fields', JSON.stringify(signatureRequest.fields));
        // add entiteId to formData
        if (signatureRequest.entiteId) {
          formData.append('entiteId', signatureRequest.entiteId);
        }

        const res = await api(`${entityName}`, {
          method: 'POST',
          headers: { contentType: 'multipart/form-data' },
          body: formData,
        });
        return res as Signature;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    sendReminders: async (signatureRequestId: string): Promise<boolean> => {
      try {
        const res = await api(`${entityName}/${signatureRequestId}/send-reminders`, {
          method: 'POST',
        });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  };

  return theApi;
};
