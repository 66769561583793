export type SearchCriteria = {
  [key: string]: SearchCriteria | { $containsi: string };
};

const getCriteria = (query: string, field: string): SearchCriteria => {
  const splittedFields = field.split('.');
  if (splittedFields.length > 1) {
    return { [splittedFields[0]]: getCriteria(query, splittedFields.slice(1).join('.')) };
  }
  return {
    [field]: {
      $containsi: query,
    },
  };
};

export const useSearchCriterias = (query: string, fields: string[]): SearchCriteria[] => {
  const result = [] as SearchCriteria[];
  for (const field of fields) {
    result.push(getCriteria(query, field));
  }
  return result;
};
