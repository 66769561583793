import type { TypeArticle } from '~/types/models/typeArticle';
import type { ApiClient, ApiResults, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'types-articles';

const entityPopulate = {
  gammes: true,
  sous_lot: { populate: ['parent'] },
  parent: {
    populate: { sous_lot: { populate: ['parent'] }, gammes: true },
  },
  dependances: true,
};

const sousTypesPopulate = {
  ...entityPopulate,
};

const searchFields = ['libelle', 'sous_types_articles.libelle'];

type Api = GenericApi<TypeArticle> & {
  fetchAll: () => Promise<ApiResults<TypeArticle> | null>;
  findSousTypes: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  findTypes: (page: number, sortField: string, query: string, limit: number) => Promise<any>;
  findTypesWithSousTypes: (
    page: number,
    sortField: string,
    query: string,
    limit: number,
  ) => Promise<any>;
  findTypesArticlesBySousLot: (lotId: number) => Promise<any>;
  searchTypes: (query: string) => Promise<any>;
  searchParentTypes: (query: string) => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, entityPopulate) as Api;

  theApi.fetchAll = async () => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = { populate: entityPopulate, filters: {}, pagination: { limit: -1 } };
      $loading.value = true;
      res = await api(`${entityName}?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res as ApiResults<TypeArticle>;
  };

  theApi.findSousTypes = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, {
      parent: { id: { $notNull: true } },
    });

  theApi.findTypes = async (page: number, sortField: string, query: string, limit: number) =>
    await theApi.find(page, sortField, query, limit, { parent: { id: { $null: true } } });

  theApi.findTypesWithSousTypes = async (
    page: number,
    sortField: string,
    query: string,
    limit: number,
  ) =>
    await theApi.find(
      page,
      sortField,
      query,
      limit,
      { parent: { id: { $null: true } } },
      { ...entityPopulate, sous_types_articles: { ...sousTypesPopulate, sort: sortField } },
    );

  theApi.findTypesArticlesBySousLot = async (lotId: number) =>
    await theApi.find(1, '', '', 100, {
      $or: [
        {
          parent: {
            sous_lot: { id: lotId },
          },
        },
        {
          sous_lot: { id: lotId },
        },
      ],
    });

  theApi.searchTypes = async (query: string) => await theApi.find(1, '', query, 5);

  theApi.searchParentTypes = async (query: string) =>
    await theApi.find(1, '', query, 5, { parent: { id: { $null: true } } });

  return theApi;
};
