import type { Diffuseur } from '~/types/models/diffusion';
import type { ApiClient } from '~~/composables/useApiFactory';

const entityName = 'ubiflow';

export default (api: ApiClient) => {
  const theApi = {
    fetchDiffuseurs: async () => {
      return (await api(`${entityName}/diffuseurs`, {})) as Diffuseur[];
    },
  };

  return theApi;
};
