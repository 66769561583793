import type { Article } from '~/types/models/article';
import type { ApiClient, ApiResults, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'articles';
const populate = {
  lot: true,
  sous_lot: { populate: { notice_section: { populate: { parent: true } } } },
  type: { populate: { parent: true } },
  gamme: true,
};
const searchFields = ['code', 'libelle'];

type Api = GenericApi<Article> & {
  search: (query: string) => Promise<ApiResults<Article> | null>;
  findArticlesBySousLot: (sousLotId: number) => Promise<ApiResults<Article> | null>;
  fetchAll: () => Promise<ApiResults<Article> | null>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.fetchAll = async () => {
    const { $loading } = useNuxtApp();
    let res;
    try {
      const params = {
        populate: populate,
        filters: { $and: [{ actif: true }, { $or: [{ temp: { $null: true } }, { temp: false }] }] },
        pagination: { limit: -1 },
      };
      $loading.value = true;
      res = await api<ApiResults<Article>>(`${entityName}?${useQs(params)}`, {});
    } catch (error) {
      console.error(error);
      res = null;
    }
    $loading.value = false;
    return res;
  };

  theApi.search = async (query: string) =>
    await theApi.find(1, '', query, 100, {
      $and: [{ actif: true }, { $or: [{ temp: { $null: true } }, { temp: false }] }],
    });

  theApi.findArticlesBySousLot = async (sousLotId: number) =>
    await theApi.find(1, '', '', -1, {
      $and: [
        { sous_lot: { id: sousLotId } },
        { $or: [{ temp: { $null: true } }, { temp: false }] },
      ],
    });

  return theApi;
};
