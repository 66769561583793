import type { User, UserWorkspaceRole } from '~/types/models/user';
import type { Workspace } from '~/types/models/workspace';

export const useUserRoles = (user: User, workspace: Workspace) => {
  return (
    user?.user_workspace_roles?.reduce((acc: string[], uwr: UserWorkspaceRole) => {
      if (uwr.workspace?.id === workspace?.id && !acc.find((r) => r === uwr.role)) {
        acc.push(uwr.role);
      }
      return acc;
    }, [] as string[]) || []
  );
};
