import type { GammeConstruction } from '~/types/models/gammeConstruction';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'construction-gammes';
const populate = { visuel: true };
const searchFields = ['libelle'];

type Api = GenericApi<GammeConstruction> & {
  isUnique: (slug: string) => Promise<any>;
  findAll: () => Promise<any>;
};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  theApi.isUnique = async (slug: string) => {
    const result = await api(`${entityName}/unique/${slug}`, {});
    return result;
  };

  theApi.findAll = async () => {
    return await theApi.find(1, '', '', 100);
  };

  return theApi;
};
