import type { Ssttfour } from '~/types/models/ssttfour';
import type { ApiClient, GenericApi } from '~~/composables/useApiFactory';

const entityName = 'ssttfours';

const populate = {
  documents: { populate: { media: true } },
};

const searchFields = ['raisonSociale'];

type Api = GenericApi<Ssttfour> & {};

export default (api: ApiClient) => {
  const theApi = useApiFactory(api, entityName, searchFields, populate) as Api;

  return theApi;
};
